import { Fragment } from "react"

import "./AboutMeSlider.scss"

// complements
import { getDiffInYears } from "../../js/utils.js"

import rafScroll from "../../complements/raf-scroll.js"

// libs
import { OverlayScrollbarsComponent } from "overlayscrollbars-react"

// components
import GlowShadow from "../GlowShadow/GlowShadow"
import Btn from "../Btn/Btn"
import Icon from "../Icon/Icon"
import IconAnimated from "../IconAnimated/IconAnimated"

import IconPresentation from "../../imgs/about-me/presentation.svg"
import IconPresentationReverse from "../../imgs/about-me/presentation-reverse.svg"
import IconCareer from "../../imgs/about-me/career.svg"
import IconCareerReverse from "../../imgs/about-me/career-reverse.svg"
import IconProfessionalLife from "../../imgs/about-me/professional-life.svg"
import IconProfessionalLifeReverse from "../../imgs/about-me/professional-life-reverse.svg"
import IconPersonalLife from "../../imgs/about-me/personal-life.svg"
import IconPersonalLifeReverse from "../../imgs/about-me/personal-life-reverse.svg"
import IconHobbiesReverse from "../../imgs/about-me/hobbies-reverse.svg"
import IconHobbies from "../../imgs/about-me/hobbies.svg"

let iconConfigs = {
	presentation: {
		fill: "none",
		stroke: "black",
		strokeWidth: 16,
		maxDuration: 3000,
		delay: 1000
	},
	career: {
		fill: "none",
		stroke: "black",
		strokeWidth: 16,
		maxDuration: 3000,
		delay: 1000
	},
	professionalLife: {
		fill: "none",
		stroke: "black",
		strokeWidth: 15,
		maxDuration: 3000,
		delay: 1000
	},
	personalLife: {
		fill: "none",
		stroke: "black",
		strokeWidth: 30,
		maxDuration: 3000,
		delay: 1000
	},
	hobbies: {
		fill: "none",
		stroke: "black",
		strokeWidth: 28,
		maxDuration: 3000,
		delay: 1000
	}
}
iconConfigs = {
	presentation: [
		iconConfigs.presentation,
		{
			...iconConfigs.presentation,
			className: "rainbow-stroke"
		},
		iconConfigs.presentation,
		iconConfigs.presentation,
		{
			...iconConfigs.presentation,
			className: "rainbow-stroke"
		},
		iconConfigs.presentation
	],
	career: [
		iconConfigs.presentation,
		{
			...iconConfigs.presentation,
			className: "rainbow-stroke"
		},
		iconConfigs.presentation,
		iconConfigs.presentation,
		{
			...iconConfigs.presentation,
			className: "rainbow-stroke"
		}
	],
	professionalLife: [
		{
			...iconConfigs.professionalLife,
			className: "rainbow-stroke"
		},
		iconConfigs.professionalLife,
		iconConfigs.professionalLife,
		iconConfigs.professionalLife,
		{
			...iconConfigs.professionalLife,
			className: "rainbow-stroke"
		},
		iconConfigs.professionalLife,
		iconConfigs.professionalLife,
		{
			...iconConfigs.professionalLife,
			className: "rainbow-stroke"
		},
		{
			...iconConfigs.professionalLife,
			className: "rainbow-stroke"
		},
		iconConfigs.professionalLife,
		{
			...iconConfigs.professionalLife,
			className: "rainbow-stroke"
		},
		{
			...iconConfigs.professionalLife,
			className: "rainbow-stroke"
		},
		{
			...iconConfigs.professionalLife,
			className: "rainbow-stroke"
		},
		iconConfigs.professionalLife,
		{
			...iconConfigs.professionalLife,
			className: "rainbow-stroke"
		},
		iconConfigs.professionalLife,
		iconConfigs.professionalLife,
		iconConfigs.professionalLife,
		iconConfigs.professionalLife,
		iconConfigs.professionalLife,
		iconConfigs.professionalLife
	],
	personalLife: [
		iconConfigs.personalLife
	],
	hobbies: [
		{
			...iconConfigs.hobbies,
			className: "rainbow-stroke"
		},
		iconConfigs.hobbies,
		iconConfigs.hobbies
	]
}

const iconObjs = [
	{
		iconImg: IconPresentation,
		iconImgReverse: IconPresentationReverse,
		config: iconConfigs.presentation
	},
	{
		iconImg: IconCareer,
		iconImgReverse: IconCareerReverse,
		config: iconConfigs.career
	},
	{
		iconImg: IconProfessionalLife,
		iconImgReverse: IconProfessionalLifeReverse,
		config: iconConfigs.professionalLife
	},
	{
		iconImg: IconPersonalLife,
		iconImgReverse: IconPersonalLifeReverse,
		config: iconConfigs.personalLife
	},
	{
		iconImg: IconHobbies,
		iconImgReverse: IconHobbiesReverse,
		config: iconConfigs.hobbies
	}
]

const sliderElementsList = [
	Presentation,
	Career,
	ProfessionalLife,
	PersonalLife,
	Hobbies
]

const age = getDiffInYears(1993, 5, 21),
	careerTime = getDiffInYears(2015, 8, 1)

function AboutMeSlider({sliderInfo}) {
	const { index, slickActions } = sliderInfo
	slickActions.showBtns = index === 0 ? false : true

	return (
		<div className="about-me-slider">
			<Slider index={index} iconObj={iconObjs[index]} slickActions={slickActions} />

			{slickActions.showBtns &&
			<div className="btn-align-position slp:block mt-4">
				{slickActions.hasBtnPrev &&
				<BtnPrev slickPrev={slickActions.slickPrev} hasBtnNext={slickActions.hasBtnNext} />}
				{slickActions.hasBtnNext &&
				<BtnNext slickNext={slickActions.slickNext} />}
			</div>}
		</div>
	)
}

function Slider({index, iconObj, slickActions}) {
	const Element = sliderElementsList[index]

	return (
		<Element iconObj={iconObj} slickActions={slickActions} />
	)
}

function Presentation({iconObj, slickActions}) {
	const { isActive, slickNext } = slickActions,
		title = "Apresentação"

	return (
		<Fragment>
			<IconAnimated iconObj={iconObj} title={title} isActive={isActive} />
			<h1 className="global-title">{title}</h1>
			<div className="hr rainbow-bg"></div>

			<OverlayScrollbarsComponent className="block-text">
				<p className="text">
					E aí, tudo bem?<br />
					Meu nome é Luis Lobo, sou paulistano, solteiro, tenho {age} anos e meus poderes são transformar cafeína em código e encontrar bugs antes mesmo deles existirem. 😅<br />
					Sou Desenvolvedor Front-end Sênior com {careerTime} anos de experiência na área e sou formado em Design gráfico.
					Gosto de conversar, faço amigos com facilidade e estou sempre disposto a ajudar.
				</p>
			</OverlayScrollbarsComponent>

			<div className="btn-align-position">
				<BtnPortfolio />
				<BtnNext slickNext={slickNext} />
			</div>
		</Fragment>
	)
}

function Career({iconObj, slickActions}) {
	const { isActive } = slickActions,
		title = "Carreira"

	return (
		<Fragment>
			<IconAnimated iconObj={iconObj} title={title} isActive={isActive} />
			<h1 className="global-title">{title}</h1>
			<div className="hr rainbow-bg"></div>

			<OverlayScrollbarsComponent className="block-text">
				<p className="text">
					Ao longo da minha carreira já trabalhei em diversos ambientes: e-commerce, agência, startup e outras empresas de diferentes tamanhos.<br />
					Tenho experiência como freelancer, com equipe enxuta, equipe multidisciplinar e squads rotativos. Com isso adquiri know how diverso e, por isso, me considero muito flexível e me adapto rápido a diferentes ambientes e desafios.<br />
				</p>
			</OverlayScrollbarsComponent>
		</Fragment>
	)
}

function ProfessionalLife({iconObj, slickActions}) {
	const { isActive } = slickActions,
		title = "Vida Profissional"

	return (
		<Fragment>
			<IconAnimated iconObj={iconObj} title={title} isActive={isActive} />
			<h1 className="global-title">{title}</h1>
			<div className="hr rainbow-bg"></div>

			<OverlayScrollbarsComponent className="block-text">
				<p className="text">
					Me considero organizado, proativo e detalhista. Sou engajado em metodologias como Scrum e Kanban e muito participativo nas reuniões.<br />
					Sou bastante responsável com projetos, prazos e qualidade do código. Por ser designer tenho facilidade em entender os requisitos da área de UI/UX e me considero uma excelente ligação entre a criatividade dos designers e a parte técnica da programação.<br />
				</p>
			</OverlayScrollbarsComponent>
		</Fragment>
	)
}

function PersonalLife({iconObj, slickActions}) {
	const { isActive } = slickActions,
		title = "Vida Pessoal"

	return (
		<Fragment>
			<IconAnimated iconObj={iconObj} title={title} isActive={isActive} />
			<h1 className="global-title">{title}</h1>
			<div className="hr rainbow-bg"></div>

			<OverlayScrollbarsComponent className="block-text">
				<p className="text">
					No dia a dia sou bem tranquilo, gosto da vida urbana, ritmo acelerado, pluralidade e facilidades.
					Sou extrovertido com colegas de trabalho, amigos e familiares, mas sou muito caseiro preferindo sair apenas em ocasiões específicas.<br />
					Sou bem introspectivo e passo o tempo pensando profundamente sobre questões cotidianas. Aprecio a solitude e aprendi a respeitar cada etapa da vida.
				</p>
			</OverlayScrollbarsComponent>
		</Fragment>
	)
}

function Hobbies({iconObj, slickActions}) {
	const { isActive } = slickActions,
		title = "Hobbies"

	return (
		<Fragment>
			<IconAnimated iconObj={iconObj} title={title} isActive={isActive} />
			<h1 className="global-title">{title}</h1>
			<div className="hr rainbow-bg"></div>

			<OverlayScrollbarsComponent className="block-text">
				<p className="text">
					Meus hobbies são todos relacionados a tecnologia: Desenvolvo alguns projetos pessoais no tempo livre e tenho evoluído muito na geração de imagens com inteligência artificial.
					Também estou aprendendo um pouquinho sobre web3. Sou gamer e os jogos são meu escapismo no fim do dia. 🎮👾🕹️📺
				</p>
			</OverlayScrollbarsComponent>
		</Fragment>
	)
}

function BtnPortfolio() {
	const htmlElement = document.documentElement,
		portfolioElement = document.querySelector("#portfolio")

	function goToPortfolio() {
		rafScroll(htmlElement, {
			y: portfolioElement.offsetTop
		}, 100)
	}

	return (
		<GlowShadow className="inline-block">
			<Btn className="bg-gray-important flip-h mr-5" onClick={goToPortfolio}>
				<Icon className="flip-h" icon="ArrowNext" stroke="white" />
				<span className="flip-h color-white">Pular para o portfólio</span>
			</Btn>
		</GlowShadow>
	)
}

function BtnPrev({hasBtnNext, slickPrev}) {
	return (
		<GlowShadow className="inline-block">
			<Btn className={`rainbow-bg ${hasBtnNext ? "mr-5" : ""}`.trim()} onClick={slickPrev}>
				<Icon icon="ArrowPrev" stroke="white" />
				<span className="color-white">Voltar</span>
			</Btn>
		</GlowShadow>
	)
}

function BtnNext({slickNext}) {
	return (
		<GlowShadow className="inline-block">
			<Btn className="rainbow-bg flip-h" onClick={slickNext}>
				<Icon className="flip-h" icon="ArrowNext" stroke="white" />
				<span className="flip-h color-white">Avançar</span>
			</Btn>
		</GlowShadow>
	)
}

export const slidersLength = sliderElementsList.length

export default AboutMeSlider
